const { email } = require('vuelidate/lib/validators')
const { vatPattern } = require('@/utils/util')

/**
 * True if passed val is a url starting with 'http:' or 'https:'.
 * @param {any} str
 * @returns {boolean}
 */
exports.isUrl = str => {
  let url

  try {
    url = new URL(str)
  } catch (_) {
    return false
  }

  return url.protocol === 'http:' || url.protocol === 'https:'
}

/**
 * True if passed var is a Number larger than zero.
 * @param {any} s
 * @returns {boolean}
 */
exports.isPositiveNumber = s => s !== null && s !== '-' && s !== '--' && s !== '' && !isNaN(s) && Number(s) >= 0

/**
 * True if passed var is a Array and has a length > 0.
 * @param {any} arr
 * @returns {boolean}
 */
exports.arrayHasItem = (arr = []) => Array.isArray(arr) && arr.length > 0

/**
 * Allows anything. Used to trigger success state on optional fields.
 * @returns {boolean}
 */
exports.any = () => true

/**
 * If there is any input it must be formatted as an email address. But its also allows "no input".
 * @param val
 * @returns {boolean}
 */
exports.optionalEmail = val => !val || email(val)

exports.euVAT = (val, code) => !val || vatPattern[code].test(val)
