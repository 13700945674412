<template>
  <base-picker
    :label="label || 'Currency'"
    :placeholder="placeholder || 'Select currency'"
    :value="value"
    @input="$emit('input', $event)"
    @focus="$emit('focus', $event)"
    @focusout="$emit('focusout', $event)"
    :items="currencies"
    :item-label="item => item.code"
    :item-value="item => item.id"
    :filter="(item, val) => item && item.code && val && item.code.toLowerCase().includes(val.toLowerCase())"
    :loading="loading"
    :success="success"
    :disabled="disabled"
    :error="error"
    :error-message="errorMessage"
    :input-name="inputName"
    :input-id="inputId"
  />
</template>

<script>
import BasePicker from '@/components/input/base/BasePicker'
export default {
  name: 'currency-picker',
  components: { BasePicker },
  props: [
    'value',
    'currencies',
    'disabled',
    'label',
    'loading',
    'error',
    'errorMessage',
    'success',
    'inputName',
    'inputId',
    'placeholder',
  ],
  emits: ['input', 'focus', 'focusout'],
}
</script>
