<template>
  <div>
    <form @keydown.prevent.enter="save">
      <div class="input-cols">
        <input-text
          label="Contact Name *"
          v-model.trim="billingForm.contact_name"
          :input-id="instanceId('contact_name')"
          @focusout="$v.billingForm.contact_name.$touch"
          :success="
            !!billingForm.contact_name && $v.billingForm.contact_name.$dirty && !$v.billingForm.contact_name.$error
          "
          input-name="billing[contact_name]"
          :error-message="$v.billingForm.contact_name.$error ? 'Please enter a name' : null"
        />

        <input-text
          label="Company name *"
          v-model.trim="billingForm.company_name"
          :input-id="instanceId('company_name')"
          input-name="billing[company_name]"
          @focusout="$v.billingForm.company_name.$touch"
          :success="$v.billingForm.company_name.$dirty && !$v.billingForm.company_name.$error"
          :error-message="$v.billingForm.company_name.$error ? 'Please enter a company name' : null"
        />

        <div class="input-cols">
          <input-text
            label="Contact Phone *"
            v-model.trim="billingForm.contact_phone"
            :input-id="instanceId('contact_phone')"
            @focusout="$v.billingForm.contact_phone.$touch"
            :success="
              !!billingForm.contact_phone && $v.billingForm.contact_phone.$dirty && !$v.billingForm.contact_phone.$error
            "
            input-name="billing[contact_phone]"
            :error-message="$v.billingForm.contact_phone.$error ? 'Please enter a phone number' : null"
          />

          <input-text
            label="Contact Email *"
            v-model.trim="billingForm.contact_email"
            :input-id="instanceId('contact_email')"
            input-name="billing[contact_email]"
            @focusout="$v.billingForm.contact_email.$touch"
            :success="
              !!billingForm.contact_email && $v.billingForm.contact_email.$dirty && !$v.billingForm.contact_email.$error
            "
            :error-message="$v.billingForm.contact_email.$error ? 'Invalid email' : null"
          />
        </div>

        <input-text
          label="Street address *"
          v-model.trim="billingForm.address"
          :input-id="instanceId('address')"
          @focusout="$v.billingForm.address.$touch"
          :success="!!billingForm.address && $v.billingForm.address.$dirty && !$v.billingForm.address.$error"
          input-name="billing[address]"
          :error-message="$v.billingForm.address.$error ? 'Please enter an address' : null"
        />

        <input-text
          label="Billing Email *"
          v-model.trim="billingForm.billing_email"
          :input-id="instanceId('billing_email')"
          input-name="billing[billing_email]"
          @focusout="$v.billingForm.billing_email.$touch"
          :success="
            !!billingForm.billing_email && $v.billingForm.billing_email.$dirty && !$v.billingForm.billing_email.$error
          "
          :error-message="$v.billingForm.billing_email.$error ? 'Invalid email' : null"
        />

        <div class="input-cols">
          <country-picker
            label="Country *"
            v-model="selectedCountry"
            :countries="countries"
            @focusout="$v.billingForm.country_id.$touch"
            :success="!!billingForm.country_id && $v.billingForm.country_id.$dirty && !$v.billingForm.country_id.$error"
            input-name="billing[country]"
            :disabled="countriesLoading"
            :input-id="instanceId('country')"
            :error-message="$v.billingForm.country_id.$error ? 'Please pick a country' : null"
          />

          <currency-picker
            label="Currency *"
            v-model="billingForm.currency_id"
            :currencies="invoiceCurrencies"
            @focusout="$v.billingForm.currency_id.$touch"
            :success="
              !!billingForm.currency_id && $v.billingForm.currency_id.$dirty && !$v.billingForm.currency_id.$error
            "
            input-name="billing[currency]"
            :disabled="currenciesLoading"
            :input-id="instanceId('currency')"
            :error-message="$v.billingForm.currency_id.$error ? 'Please pick a currency' : null"
          />
        </div>

        <div class="input-cols">
          <input-text
            label="City *"
            v-model.trim="billingForm.city"
            :input-id="instanceId('city')"
            @focusout="$v.billingForm.city.$touch"
            :success="!!billingForm.city && $v.billingForm.city.$dirty && !$v.billingForm.city.$error"
            input-name="billing[city]"
            :error-message="$v.billingForm.city.$error ? 'Please enter a city' : null"
          />

          <input-text
            label="Postal code *"
            v-model.trim="billingForm.post_code"
            :input-id="instanceId('post_code')"
            @focusout="$v.billingForm.post_code.$touch"
            :success="!!billingForm.post_code && $v.billingForm.post_code.$dirty && !$v.billingForm.post_code.$error"
            input-name="billing[post_code]"
            :error-message="$v.billingForm.post_code.$error ? 'Please enter a post code' : null"
          />
        </div>
        <input-text
          v-if="vatCountry || (!!billing && !!billing.vat)"
          label="VAT Number *"
          v-model.trim="billingForm.vat"
          :disabled="!!billing && !!billing.vat"
          :input-id="instanceId('vat')"
          @focusout="$v.billingForm.vat.$touch"
          :success="!!billingForm.vat && $v.billingForm.vat.$dirty && !$v.billingForm.vat.$error"
          :error-message="$v.billingForm.vat.$error ? 'Requires a valid VAT' : null"
          input-name="billing[vat]"
        />
      </div>
    </form>

    <transition
      name="fade"
      mode="out-in"
    >
      <div
        v-if="anyError"
        class="text-xs mb-4 p-4 bg-red-200 text-red-600"
      >
        An error occurred.
        <span
          @click="showErrorDetails = !showErrorDetails"
          class="link-primary"
        >
          Details
          <ic-chevron
            direction="down"
            :size="18"
          />
        </span>
        <div v-if="showErrorDetails">
          <pre
            v-if="!!billingsError"
            class="text-black"
          >
Could not load billings: {{ billingsError }}</pre
          >
          <pre
            v-if="!!getCreateBillingError"
            class="text-black"
          >
Could not create billing: {{ getCreateBillingError }}</pre
          >
          <pre
            v-if="!!getUpdateBillingError"
            class="text-black"
          >
Could not update billing: {{ getUpdateBillingError }}</pre
          >
        </div>
      </div>
    </transition>

    <slot
      name="action-btn"
      :save="save"
      :loading="loading"
      :savingBilling="savingBilling"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { required, requiredIf } from 'vuelidate/lib/validators'
import { optionalEmail, euVAT } from '@/utils/validators'
import { newReference } from '@/utils/util'
import CountryPicker from '@/components/input/CountryPicker'
import CurrencyPicker from '@/components/input/CurrencyPicker'
import InputText from '@/components/input/InputText'
import IcChevron from 'vue-material-design-icons/ChevronDown'
import BasePicker from '@/components/input/base/BasePicker'
import BillingForm from '@/components/account/BillingForm.vue'
import Toast from '@/components/shared/Toast'

const EMPTY_BILLING = {
  company_name: null,
  vat: null,
  address: null,
  post_code: null,
  city: null,
  country_id: null,
  currency: null,
  billing_email: null,
  contact_name: null,
  contact_email: null,
  contact_phone: null,
  adspend_fee_stacked: false,
}

export default {
  name: 'account-billing-form',
  components: { BasePicker, IcChevron, InputText, CountryPicker, CurrencyPicker, BillingForm, Toast },
  emits: ['saved', 'cancel'],
  data() {
    return {
      savingBilling: false,
      billingForm: newReference(EMPTY_BILLING),
      selectedCountry: null,
      showErrorDetails: false,
    }
  },
  props: {
    billing: {
      type: Object,
    },
  },
  modalProps: {
    height: 'auto',
    maxWidth: 900,
    scrollable: true,
  },
  validations() {
    return {
      billingForm: {
        company_name: { required },
        billing_email: { required, optionalEmail },
        contact_email: { required, optionalEmail },
        address: { required },
        post_code: { required },
        city: { required },
        country_id: { required },
        currency_id: { required },
        vat: {
          required: requiredIf(function () {
            return this.vatCountry
          }),
          euVAT: function (val) {
            return euVAT(val, this.selectedCountry.code)
          },
        },
        contact_name: { required },
        contact_phone: { required },
      },
    }
  },
  computed: {
    ...mapGetters({
      storeId: 'store/selectedStoreId',
      loadingBillings: 'billing/isLoadingAccountBillings',
      billingsError: 'billing/getAccountLoadBillingsError',
      countries: 'country/getCountries',
      countriesLoading: 'country/isCountriesLoading',
      invoiceCurrencies: 'invoice/getCurrencies',
      currenciesLoading: 'invoice/isCurrenciesLoading',
      getCreateBillingError: 'billing/getCreateBillingError',
      getUpdateBillingError: 'billing/getUpdateBillingError',
    }),
    loading() {
      return this.loadingBillings || this.countriesLoading || this.savingBilling || this.currenciesLoading
    },
    anyError() {
      return this.getCreateBillingError || this.getUpdateBillingError || this.billingsError
    },
    vatCountry() {
      if (this.selectedCountry) {
        return !!this.selectedCountry.vat_required
      }
    },
  },
  watch: {
    billing(_) {
      this.populateForm()
    },
    selectedCountry(country) {
      this.billingForm.country_id = country && country.id ? country.id : null
    },
    savingBillingError(e) {
      if (e)
        this.$toast.error({
          component: Toast,
          props: {
            title: 'Error',
            message: 'Could not save billing',
            type: 'error',
          },
        })
    },
  },
  methods: {
    ...mapActions({
      loadCountriesIfNeeded: 'country/loadCountriesIfNeeded',
      createBilling: 'billing/createBilling',
      updateBilling: 'billing/updateBilling',
    }),
    instanceId(uniqueName) {
      return `${this._uid}:billing:${uniqueName}`
    },
    populateForm() {
      if (!this.billing) {
        return
      }
      this.selectedCountry = this.billing.country && this.billing.country.id ? this.billing.country : null
      this.billingForm = {
        company_name: this.billing.company_name,
        billing_email: this.billing.billing_email,
        contact_email: this.billing.contact_email,
        address: this.billing.address,
        post_code: this.billing.post_code,
        city: this.billing.city,
        country_id: this.billing.country ? this.billing.country.id : null,
        currency_id: this.billing.currency.id,
        vat: this.billing.vat,
        contact_name: this.billing.contact_name,
        contact_phone: this.billing.contact_phone,
      }
    },
    submitted() {},
    save: async function () {
      this.$v.billingForm.$touch()
      if (this.$v.billingForm.$invalid) {
        return
      }
      this.savingBilling = true
      const createNewBilling = !this.billing
      if (createNewBilling) {
        await this.createBilling({ ...this.billingForm, store_id: this.storeId })
      } else {
        await this.updateBilling({
          billingId: this.billing.id,
          formData: { ...this.billingForm, store_id: this.storeId },
        })
      }

      this.$emit('saved')
      this.savingBilling = false
      this.$toast.success({
        component: Toast,
        props: {
          title: 'Success',
          message: 'Billing saved',
          type: 'success',
        },
      })
    },
  },
  async mounted() {
    await this.loadCountriesIfNeeded()
    this.populateForm()
  },
  destroyed() {
    if (!this.$v.billingForm.$invalid) {
      this.$emit('saved')
    }
  },
}
</script>
<style lang="scss" scoped>
.input-cols {
  @apply grid gap-y-1;

  @screen sm {
    @apply grid-cols-2 gap-x-8;
  }

  &:last-child {
    margin-bottom: 0;
  }
}
</style>
