<template>
  <base-filter-picker
    :label="label || 'Country'"
    :placeholder="placeholder || 'Select country'"
    :value="value"
    @input="$emit('input', $event)"
    @focus="$emit('focus', $event)"
    @focusout="$emit('focusout', $event)"
    :items="countries"
    :required="required"
    :item-label="item => item.name"
    :item-value="item => item"
    :filter="(item, val) => item && item.name && val && item.name.toLowerCase().includes(val.toLowerCase())"
    :loading="loading"
    :success="success"
    :disabled="disabled"
    :error="error"
    :error-message="errorMessage"
    :input-name="inputName"
    :input-id="inputId"
  />
</template>

<script>
import BaseFilterPicker from '@/components/input/base/BaseFilterPicker'

export default {
  name: 'country-picker',
  components: { BaseFilterPicker },
  props: [
    'value',
    'countries',
    'disabled',
    'label',
    'loading',
    'error',
    'errorMessage',
    'success',
    'inputName',
    'inputId',
    'placeholder',
    'required',
  ],
  emits: ['input', 'focus', 'focusout'],
}
</script>
